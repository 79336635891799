@import "@/scss/_variables.scss";






















































































































.filter-background {
  min-height: $input-default-height * 2;
  background-color: #7b7872;
  .form-text {
    width: 80%;
  }
}

