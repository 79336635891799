@import "@/scss/_variables.scss";































.dropdown-widget {
  height: $input-default-height !important;
}
