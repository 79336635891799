@import "@/scss/_variables.scss";























































































$dropdown-width: 180px;
.dropdown {
  width:$dropdown-width;
  position:absolute;
  left:0;
}
.form-text {
  width: 100%;
  padding-left: $dropdown-width + 10;
}
